.Loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
}

.Square {
  display: inline-block;
  animation: Square 3s 0s infinite cubic-bezier(0.09, 0.57, 0.49, 0.9);
  animation-fill-mode: both;
}

@keyframes Square {
  25% {
    transform: rotateX(180deg) rotateY(0);
  }
  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }
  75% {
    transform: rotateX(0) rotateY(180deg);
  }
  100% {
    transform: rotateX(0) rotateY(0);
  }
}
