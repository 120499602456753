body {
  margin: 0;
  background-color: white;
  font-family: "Open Sans", sans-serif;
}

:root {
  --color-primary: #009de0;
  --color-secondary: #5a2572;
  --color-accent: #fabb00;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

p,
a,
li,
legend,
label,
span,
input,
textarea {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}

a,
button.link {
  text-decoration: none;
  color: var(--color-primary);
}

button.link {
  font-family: inherit;
  font-size: 15px;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  outline: none;
}

.main {
  padding-top: 70px;
  padding-bottom: 60px;
}

.main.main_gray {
  background-color: #f3f3f3;
}

.content {
  padding: 0 20px;
}

* {
  box-sizing: border-box;
}

.show .card-body {
  display: block;
}

.card-body {
  display: none;
}

button.btn.btn-link {
  border: 0;
  background: none;
  padding: 0;
  font-size: 17px;
  display: block;
  width: 100%;
  text-align: left;
}
.card {
  padding-bottom: 20px;
  border-bottom: 1px solid;
  padding-top: 20px;
}
.slick-slider {
  overflow: hidden;
}

.slick-slide {
  padding: 0 20px;
}

.m0 {
  margin-bottom: 0;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

ul.slick-dots {
  bottom: -4px;
}
ul.slick-dots li button:before {
  color: var(--color-primary);
  font-size: 10px;
}
ul.slick-dots li.slick-active button:before {
  color: var(--color-primary);
}

.errorText {
  margin: 1rem 0;
  color: red;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 600;
}
